<template>
  <div>
    <v-card class="elevation-12">
      <v-toolbar dark color="info">
        <v-toolbar-title>
          <span> {{ title }}</span>
        </v-toolbar-title>
        <v-spacer></v-spacer>
        <v-tooltip bottom>
          <template v-slot:activator="{ on: on }">
            <v-btn icon v-on="on" :to="{ name: 'login' }">
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </template>
          <span>{{ $i18n.translate("Cancel") }}</span>
        </v-tooltip>
      </v-toolbar>
      <v-card-text>
        <p>Your enrollment in the {{ selectedProgram.name }} program is still pending verification.</p>
        <p>
          If you feel this is an error, please contact us via email at {{ selectedProgram.programEmail }} or by phone by
          {{ selectedProgram.programPhone }}
        </p>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  name: "PreEnroll",

  data() {
    return {};
  },
  computed: {
    ...mapGetters(["selectedProgram"]),
    title() {
      return "Your Enrollment is still pending verification";
    }
  }
};
</script>
